<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:54:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('project.evcharger-type')}}</span>
                <el-input v-model="chargerType" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            
            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-button type="primary" class="marginR10" icon='el-icon-plus' @click="()=>{$router.push({path:'/addEvCharger'})}">{{$t('project.evcharger-add')}}</el-button>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
                    @confirm='tchargepileDeleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
            <!-- <el-button type="primary" icon='el-icon-upload2' class="pull-right" @click="dialogVisible = true">{{$t('system.import')}}</el-button> -->
        </div>
        <!-- :data="dataRows" -->
        <baseTable v-loading="loading"  row-key='sid'  border  stripe  :data="dataRows"  :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #name='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data.id)'>{{data.name}}</a>
            </template>
            <template #option="{data}">
                <div class="option-box">
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')" @confirm='tchargepileDelete(data.id)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog
                :title="$t('system.city.import')"
                :visible.sync="dialogVisible"
                width="30%"
                v-if='dialogVisible'
        >
            <el-button type="text" @click="download">
                {{$t('system.template.upload')}}
            </el-button>
            <base-file v-model="uploadFile" ></base-file>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" :loading="loadingBtn" @click="submitFile">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile
    },
    props: {

    },
    data() {
        return {
            chargerType:'',
            uploadFile:'',
            dialogVisible: false,
            chargerIds:[],
            name:'',
            loading:false,
            loadingBtn:false,
            dataRows:[],
            column:[
            {
                title: this.$t('project.evcharger-type'),
                value: 'name',
                slot: 'name'
            },{
                title: this.$t('evcharger.data.Phases/Lines'),
                value: 'pilePhase',
            },{
                title: this.$t('evcharger.data.Input-Voltage')+'V',
                value: 'inputVoltage',
            },{
                title: this.$t('evcharger.data.Output-Voltage')+"V",
                value: 'outputVoltage',
            },{
                title: this.$t('evcharger.data.Output-Current')+'A',
                value: 'outputCurrent',
            },{
                title: this.$t('evcharger.data.Output-Power')+'kW',
                value: 'outputPower',
            }
            ,{
                title: this.$t('evcharger.data.Charging-Outlet'),
                value: 'chargingOutLet',
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.tchargepileGetPage();
    },
    methods: {

        /*导入模板下载*/
        download(){
            window.open(window.location.href.split('#')[0] + 'static/charger.xls')
        },


        /*提交文件*/
        async submitFile() {
            if (!this.uploadFile) {
                this.$message({
                    message: this.$t('remind.upload-file-first'),
                    type: 'warning'
                });
                return
            }
            this.loadingBtn = true
            let res = await this.API.systemTCityImport({
                file: this.uploadFile
            })
            this.loadingBtn = false
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.dialogVisible = false
                this.uploadFile = ''
                this.tchargepileGetPage()
            }else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        


        /*充电桩列表分页*/
        async tchargepileGetPage(){
            this.loading = true
            let res = await this.API.tchargepileGetPage({
                name:this.chargerType,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },

        /*删除*/
        async tchargepileDelete(id){
            this.chargerIds = []
            this.chargerIds.push(id)
            this.tchargepileDeleteBatch()
        },

        /*批量删除*/
        async tchargepileDeleteBatch(){
            let res = await this.API.tchargepileDeleteBatch({
                ids:this.chargerIds
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tchargepileGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        /*跳转详情页面*/
        pushEditUrl(id){
            this.$router.push({path: '/addEvCharger', query: {id:id}});
        },


        query(){
            this.current = 1
            this.tchargepileGetPage()
        },
       
        handleSelectionChange(val){
          this.chargerIds=val.map( item=>{
            return item.id
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.tchargepileGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.tchargepileGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>

</style>
